/* Desktop - 100 */

@media only screen {

    .project-about {
        height: auto;
        width: 150vw;
        transform: translateX(-22.6%);
        background-color: black;
        color: white;
        flex-direction: column;
    }

    .project-about-top {
        width: 54.8%;
        margin-top: 75px;
        height: auto;
    }

    .project-about-lists {
        width: 42%;
        min-width: 400px;
    }

    .project-about-list-1-animation {
        animation: fadeInRightToLeft .45s ease-in-out forwards;
    }

    .project-about-list-2-animation {
        animation: fadeInRightToLeft .6s ease-in-out forwards;
    }

    .project-about-list-3-animation {
        animation: fadeInRightToLeft .7s ease-in-out forwards;
    }

    .project-about-lists-x {
        margin-right: 20px;
    }

    .project-about-lists-x p {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 17px;
        letter-spacing: .2px;
    }

    .project-about-lists-x-li div {
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 15px;
        color: rgb(178, 178, 178);
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    .project-about-subscribe {
        min-width: 28%;
        min-height: 215px;
        padding-right: 30px;
        padding-left: 38px;
        background-color: #201c1c;
        border-radius: 6px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .project-about-subscribe-head {
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        margin-top: 32px;
        letter-spacing: .2px;
    }

    .project-about-subscribe-input {
        margin-top: 20px;
    }

    .project-about-subscribe-input input {
        color: #7A7E92;
        padding-left: 18px;
        font-size: 14px;
        width: 75%;
        height: 40px;
        border-radius: 5px 0px 0px 5px;
        border: none;
    }

    .project-about-subscribe-input input:focus {
        outline: none;
    }

    .project-about-subscribe-arrow-button {
        height: 42px;
        border-radius: 0px 5px 5px 0px;
        width: 42px;
        background-color: #4541FF;
    }

    .project-about-subscribe-input img {
        height: 20px;
    }

    .project-about-subscribe-desc {
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        max-width: 250px;
        min-width: 300px;
        font-size: 12px;
        color: rgb(163, 163, 163);
    }

    .project-about-hr {
        margin-top: 50px;
        margin-bottom: 50px;
        height: 1px;
        width: 55%;
        background-color: #7A7E92;
    }

    .project-about-bottom {
        width: 55%;
        margin-bottom: 80px;
    }

    .project-about-bottom-left {
        width: 30%;
    }

    .project-about-logo {
        height: 20px;
        margin-right: 50px;
    }

    .project-about-privacy-cookies {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        letter-spacing: .5px;
        min-width: 210px;
        margin-right: 25px;
    }

    .project-about-bottom-left span {
        margin-right: 40px;
        cursor: pointer;
    }

    .project-about-bottom-right {
        min-width: 17%;
    }

    .project-about-social-1-animation {
        animation: fadeInLeftToRight .4s ease-in-out forwards;
    }

    .project-about-social-2-animation {
        animation: fadeInLeftToRight .5s ease-in-out forwards;
    }

    .project-about-social-3-animation {
        animation: fadeInLeftToRight .6s ease-in-out forwards;
    }

    .project-about-social-4-animation {
        animation: fadeInLeftToRight .7s ease-in-out forwards;
    }

    .project-about-social {
        border: 1px solid rgba(106, 106, 106, 0.855);
        border-radius: 50%;
        width: 38px;
        height: 38px;
        min-width: 38px;
        min-height: 38px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {

    .project-about-top {
        margin-top: 60px;
    }

    .project-about-lists {
        min-width: 320px;
    }

    .project-about-lists-x {
        margin-right: 16px;
    }

    .project-about-lists-x p {
        font-size: 14px;
    }

    .project-about-lists-x-li div {
        margin-bottom: 8px;
        font-size: 12px;
    }

    .project-about-subscribe {
        min-height: 172px;
        padding-right: 24px;
        padding-left: 31px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .project-about-subscribe-head {
        font-size: 13.5px;
        margin-top: 25.5px;
    }

    .project-about-subscribe-input {
        margin-top: 16px;
    }

    .project-about-subscribe-input input {
        padding-left: 14.5px;
        font-size: 11px;
        height: 32px;
        border-radius: 4px 0px 0px 4px;
    }

    .project-about-subscribe-arrow-button {
        height: 34px;
        border-radius: 0px 4px 4px 0px;
        width: 33.5px;
    }

    .project-about-subscribe-input img {
        height: 16px;
    }

    .project-about-subscribe-desc {
        margin-top: 20px;
        max-width: 200px;
        min-width: 240px;
        font-size: 10px;
    }

    .project-about-hr {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .project-about-bottom {
        margin-bottom: 64px;
    }

    .project-about-logo {
        height: 16px;
        margin-right: 40px;
    }

    .project-about-privacy-cookies {
        font-size: 11px;
        min-width: 168px;
        margin-right: 20px;
    }

    .project-about-bottom-left span {
        margin-right: 32px;
    }

    .project-about-social {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        margin-right: 12px;
    }

    .project-about-social img {
        height: 12px;
    }
}