/* Desktop - 100 */

@media only screen {

    .home-clients {
        margin-top: 190px;
        margin-bottom: 200px;
    }

    .home-clients-text {
        width: 570px;
        word-spacing: 2px;
    }

    .home-clients-text-head {
        margin-bottom: 50px;
        font-size: 78px;
        line-height: 75px;
        animation: fadeInRightToLeft .5s ease-in-out forwards;
    }

    .home-clients-text-body {
        font-size: 23px;
        line-height: 27px;
        animation: fadeInRightToLeft .6s ease-in-out forwards;
    }

    .home-clients-images {
        align-items: baseline;
    }

    .home-clients-images-animation {
        animation: fadeInLeftToRightClient .7s ease-in-out forwards;
    }

    @keyframes fadeInLeftToRightClient {
        from {
            opacity: 0;
            transform: translateX(-3vw);
        }

        to {
            opacity: 1;
            transform: translateX(5vw);
        }
    }

    .home-clients-images img {
        height: 155px;
    }

    .home-client-faded-box-1 {
        min-width: 120px;
        min-height: 200px;
        background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        transform: translateX(120px);
    }

    .home-client-faded-box-2 {
        min-width: 150px;
        min-height: 200px;
        background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        transform: translateX(-140px);
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {

    .home-clients {
        margin-top: 155px;
        margin-bottom: 160px;
    }

    .home-clients-text {
        min-width: 480px !important;
    }

    .home-clients-text-head {
        margin-bottom: 40px;
        font-size: 64px;
        line-height: 60px;
    }

    .home-clients-text-body {
        font-size: 19px;
        line-height: 22px;
    }

    .home-clients-images img {
        height: 125px;
    }

    .home-client-faded-box-1 {
        min-height: 160px;
    }

    .home-client-faded-box-2 {
        min-height: 160px;
    }
}