/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-home-experience-design {
        width: 92vw;
        padding-left: 5%;
    }

    .mobile-home-exp-mini-heading {
        margin-top: 35px;
        font-size: 10px;
        color: #A3A1A1;
        letter-spacing: .3px;
    }

    .mobile-home-exp-heading {
        width: 90vw;
        margin-top: 20px;
        font-size: 21px;
        line-height: 27px;
        letter-spacing: .2px;
    }

    .mobile-home-exp-desc {
        width: 88vw;
        margin-top: 25px;
        font-size: 13px;
        color: #565656;
        letter-spacing: .2px;
        line-height: 17px;
    }

    .mobile-home-exp-work-connect {
        margin-top: 45px;
        align-items: end;
        margin-bottom: 30px;
    }

    .mobile-home-exp-our-work {
        font-size: 22px;
        letter-spacing: .5px;
    }

    .mobile-home-exp-connect {
        margin-right: 7px;
    }

    .mobile-home-exp-connect a {
        text-decoration: none;
        color: inherit;
    }

    .mobile-home-exp-lets-connect {
        margin-right: 8px;
        color: #1942F2;
        font-size: 14px;
    }

    .mobile-home-exp-connect img {
        height: 19px;
        width: 19px;
    }
}