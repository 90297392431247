/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-clients {
        width: 90%;
        margin-left: 5%;
        margin-top: 55px;
        margin-bottom: 50px;
    }

    .mobile-clients-head {
        font-size: 30px;
    }

    .mobile-clients-body {
        margin-top: 18px;
        margin-bottom: 20px;
        font-size: 13px;
        letter-spacing: .5px;
    }

    .mobile-clients-images img {
        height: 100px;
        margin-left: 25px;
    }
}