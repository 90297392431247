/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-home-introduction {
        width: 100vw;
        height: auto;
        padding-bottom: 25px;
        background-color: black;
        z-index: 0;
    }

    .mobile-home-intro-gif {
        max-width: 85%;
        top: 35vw;
        left: 16%;
    }

    .mobile-home-intro-gif img {
        width: 125%;
        opacity: .5;
    }

    .mobile-home-intro-rest {
        height: 435px;
        width: 95%;
        margin-left: 5%;
        margin-top: 32vh;
        justify-content: end;
        color: white;
        flex-direction: column;
    }

    .mobile-home-intro-main-quote {
        font-size: 40px;
        letter-spacing: .3px;
    }

    .mobile-home-intro-quote-1 {
        width: 70%;
    }

    .mobile-home-intro-quote-2 {
        width: 74.5vw;
    }

    .mobile-home-intro-sub-quote {
        margin-top: 4.5vh;
        margin-left: 2px;
    }

    .mobile-home-intro-sub-quote-text {
        font-size: 15px;
        letter-spacing: .2px;
    }

    .mobile-home-intro-sub-quote-icon {
        height: 14px;
        margin-left: 5px;
    }

    .mobile-home-sliding-box-list {
        overflow-x: scroll;
        margin-top: 4.5vh;
        scrollbar-width: none;
    }

    .mobile-home-sliding-box-list::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }

    .sliding-box {
        min-width: 55%;
        min-height: 11vh;
        margin: 10px 10px 10px 0px;
        border-radius: 7px;
        background-image: url('../../../../public/homepage_assets/images/sliding-box-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        border: .5px inset rgb(77, 77, 77);
        justify-content: start;
    }

    .sliding-box-text {
        width: 60%;
        margin-left: 20px;
        font-size: 15px;
        letter-spacing: .2px;
        line-height: 20px;
    }
}