/* Mobile */

@media only screen and (max-width: 500px) {

    /* Contact Us */

    .mobile-contact-us {
        width: 78vw;
        height: 110px;
        margin-left: 5vw;
        color: white;
        border-radius: 10px;
        transform: translateY(-50vw);
        background-color: #1942F2;
        padding-left: 7vw;
        padding-right: 5vw;
        z-index: 1;
    }

    .mobile-contact-us-text {
        width: 50%;
        font-size: 20px;
    }

    .mobile-contact-us-button {
        height: 26px;
        width: 95px;
        padding-bottom: 2px;
        border: 1px solid white;
        font-size: 12px;
        border-radius: 3px;
    }

    .mobile-contact-us-button a {
        text-decoration: none;
        color: inherit;
    }
}