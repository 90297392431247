@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
}

body::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

html {
    scrollbar-width: none;
}

/* My Library */

.my-flex {
    display: flex;
}

.ai-center {
    align-items: center;
}

.jc-center {
    justify-content: center;
}

.jc-space-bw {
    justify-content: space-between;
}

.my-absolute {
    position: absolute;
}

.o-hidden {
    overflow: hidden;
}

.o-x-hidden {
    overflow-x: hidden;
}

.my-pointer {
    cursor: pointer;
}

.sk-modernist {
    font-family: 'Sk-Modernist';
}

.sk-modernist-l {
    font-family: 'Sk-Modernist-Light';
}

.sk-modernist-b {
    font-family: 'Sk-Modernist-Bold';
}

.my-bold {
    font-weight: bold;
}

.my-no-select {
    user-select: none;
}

.fade-out {
    opacity: 0;
}

.my-button-click {
    transition: transform .5s ease;
}

.my-button-click:active {
    transform: scale(.85);
}

.my-button-click-w {
    transition: transform .3s ease;
}

.my-button-click-w:active {
    transform: scale(1.1);
}

.z-i-m {
    animation: zoomIn .5s ease-in-out forwards;
}

/* My Animations */

@keyframes FadeIn {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes moveInFromTop {
    from {
        transform: translateY(-350px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes moveInFromBottom {
    from {
        transform: translateY(350px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes moveInLeftToRight {
    from {
        transform: translateX(-50px);
    }

    to {
        transform: translateX(0);
    }
}

.m-i-l-r {
    opacity: 1;
    animation: moveInLeftToRight .7s ease-in-out forwards;
}

@keyframes moveInRightToLeft {
    from {
        transform: translateX(50px);
    }

    to {
        transform: translateX(0);
    }
}

.m-i-r-l {
    animation: moveInRightToLeft .7s ease-in-out forwards;
    opacity: 1;
}

@keyframes fadeInLeftToRight {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.f-i-l-r {
    animation: fadeInLeftToRight .7s ease-in-out forwards;
}

@keyframes fadeInRightToLeft {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.f-i-r-l {
    animation: fadeInRightToLeft .7s ease-in-out forwards;
    opacity: 0;
}

.f-i-r-l-m {
    animation: fadeInRightToLeft .4s ease-in-out forwards;
    opacity: 0;
}

@keyframes FadeInFromBottom {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.f-i-f-b {
    animation: FadeInFromBottom .5s ease-in-out forwards;
}