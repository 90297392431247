/* Desktop - 100 */

@media only screen {

    .achievement-box {
        width: 90.5%;
        padding: 50px 70px 55px 85px;
        z-index: 3;
        background-color: white;
        transform: translateY(-50%);
        border-radius: 22px;
        border: 1px solid rgba(130, 130, 130, 0.44);
    }

    .achievement-left-container {
        height: 380px;
        width: 390px;
        min-width: 390px;
    }

    .achievement-left {
        border-radius: 25px;
        margin-right: 50px;
        animation: zoomIn .5s ease-in-out forwards;
    }

    @keyframes zoomIn {
        from {
            transform: scale(.9);
            opacity: 0;
        }

        to {
            transform: scale(1);
            opacity: 1;
        }
    }

    .achievement-left-container img {
        height: 410px;
        margin-top: 5px;
    }

    .achievement-right {
        width: 65%;
    }

    .achievement-right-head-container {
        width: 620px;
        height: 106px;
        margin-bottom: 35px;
        margin-right: 20px;
    }

    .achievement-right-head {
        font-size: 42px;
        line-height: 53px;
        letter-spacing: 1px;
        animation: fadeInRightToLeft .5s ease-in-out forwards;
    }

    .achievement-right-body-container {
        width: 99%;
        height: 104px;
        margin-bottom: 44px;
    }

    .achievement-right-body {
        font-size: 20px;
        color: #565656;
        line-height: 26px;
        letter-spacing: .7px;
        word-spacing: 1px;
        animation: fadeInRightToLeft .5s ease-in-out forwards;
    }

    .achievement-right-badges-container {
        width: 80%;
        margin-bottom: 15px;
    }

    .achievement-right-badges {
        width: 100%;
        animation: fadeInRightToLeft .5s ease-in-out forwards;
    }

    .achievement-badge {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
    }

    .achievement-value {
        margin-bottom: 10px;
        font-family: 'Sk-Modernist-Bold';
        font-size: 52px;
    }

    .achievement-name {
        width: 140px;
        letter-spacing: .6px;
        font-size: 17px;
        font-family: 'Sk-Modernist';
        color: #565656;
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {

    .achievement-box {
        padding: 38px 56px 42px 65px;
        border-radius: 19px;
    }

    .achievement-left-container {
        height: 300px;
        width: 305px;
        min-width: 305px;
    }

    .achievement-left {
        border-radius: 19px;
        margin-right: 40px;
    }

    .achievement-left-container img {
        height: 330px;
        margin-top: 4px;
    }

    .achievement-right-head-container {
        width: 510px;
        height: 80px;
        margin-bottom: 30px;
        margin-right: 16px;
    }

    .achievement-right-head {
        font-size: 35px;
        line-height: 44px;
    }

    .achievement-right-body-container {
        height: 83px;
        margin-bottom: 30px;
    }

    .achievement-right-body {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.3px;
        word-spacing: .5px;
    }

    .achievement-right-badges-container {
        margin-bottom: 10px;
        width: 90%;
    }

    .achievement-badge {
        margin-right: 24px;
    }

    .achievement-value {
        margin-bottom: 6px;
        font-size: 41px;
    }

    .achievement-name {
        width: 112px;
        letter-spacing: .3px;
        font-size: 13px;
    }
}