/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-services {
        width: 95%;
        height: auto;
        background-color: black;
        color: white;
        padding-top: 30px;
        padding-left: 5%;
    }

    .mobile-services-animation-ball {
        height: 80vw;
        transform: translateY(-47vw) translateX(24vw);
    }

    .mobile-services-others {
        transform: translateY(-82vw);
    }

    .mobile-services-head {
        font-size: 11.5vw;
    }

    .mobile-services-list {
        margin-top: 20px;
        transform: translateX(-5vw);
    }

    .mobile-service-box {
        width: 110vw;
        padding-left: 10vw;
        padding-top: 40px;
        padding-bottom: 45px;
    }

    .mobile-service-box-head {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .mobile-service-box-body {
        width: 71vw;
        font-size: 15px;
        margin-bottom: 30px;
    }

    .mobile-service-box-learn-more-text {
        border-bottom: .8px solid white;
        letter-spacing: .5px;
        font-size: 16px;
    }

    .mobile-service-box-learn-more-icon {
        height: 18px;
        margin-left: 8px;
    }

    .mobile-services-get-in-touch {
        width: 95%;
        height: 45px;
        border: .8px solid white;
        border-radius: 7px;
        margin-top: 30px;
        padding-bottom: 3px;
    }

    .mobile-services-get-in-touch a {
        color: inherit;
        text-decoration: none;
    }
}