/* Desktops- 100 */

@media screen {

    .home-introduction {
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .home-introduction-background {
        width: 82%;
        height: 52vh;
        background: url("../../../../public/homepage_assets/images/home-background.png") no-repeat center;
        background-size: cover;
        background-clip: padding-box;
        padding: 22vh 9% 23vh;
    }

    .home-introduction-quote {
        height: 500px;
        padding-left: 20px;
        flex-direction: column;
        color: white;
    }

    .home-introduction-main-quote {
        flex: 2;
        margin-top: 40px;
        font-size: 62px;
        letter-spacing: 1px;
        word-spacing: 3px;
        line-height: 1.25;
    }

    .home-introduction-quote-1 {
        width: 600px;
        animation: fadeInLeftToRight .7s ease-in-out forwards;
    }

    .home-introduction-quote-2 {
        width: 700px;
        animation: fadeInLeftToRight .7s ease-in-out forwards;
    }

    .home-introduction-sub-quote {
        flex: 1;
        font-size: 25px;
        margin-left: 5px;
        color: rgb(209, 209, 209);
        font-weight: lighter;
        letter-spacing: .5px;
    }

    .home-introduction-sub-quote-icon {
        margin-left: 15px;
        height: 30px;
        padding-bottom: 1px;
    }

    .home-introduction-gif {
        margin-right: 30px;
        margin-top: 15px;
    }

    .home-introduction-gif img {
        height: 48vh !important;
    }

    .home-hidden-part {
        margin-top: 100px;
        width: 82%;
    }

    .home-hidden-part-left {
        width: 450px !important;
        font-size: 50px;
        line-height: 60px;
    }

    .home-hidden-part-right {
        width: 450px !important;
        padding-right: 3px;
        margin-right: 2px;
    }

    .home-hidden-part-right-top {
        width: 100%;
        margin-bottom: 20px;
        line-height: 30px;
        font-size: 20px;
    }

    .home-hidden-part-lets-connect {
        margin-right: 12px;
        color: #1942F2;
        font-size: 20px;
    }

    .home-hidden-part-right-bottom img {
        height: 23px;
        width: 23px;
    }
}

/* Laptops - 125 */

@media only screen and (max-width: 1600px) {

    .home-introduction-quote {
        height: 400px;
    }

    .home-introduction-main-quote {
        flex: 2;
        margin-top: 34px;
        font-size: 49px;
    }

    .home-introduction-quote-1 {
        width: 500px;
    }

    .home-introduction-quote-2 {
        width: 600px;
    }

    .home-introduction-sub-quote {
        flex: 1;
        font-size: 21px;
    }

    .home-introduction-sub-quote-icon {
        margin-left: 13px;
        height: 25px;
    }

    .home-introduction-gif {
        margin-right: 25px;
        margin-top: 13px;
    }

    .home-introduction-gif img {
        height: 370px;
    }

    .home-hidden-part {
        margin-top: 85px;
    }

    .home-hidden-part-left {
        width: 350px !important;
        font-size: 40px;
        line-height: 48px;
    }

    .home-hidden-part-right {
        width: 350px !important;
    }

    .home-hidden-part-right-top {
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 14px;
    }

    .home-hidden-part-lets-connect {
        font-size: 16px;
        margin-right: 9px;
    }

    .home-hidden-part-right-bottom img {
        height: 20px;
        width: 20px;
    }

    @keyframes fadeInIntroQuote-1 {
        from {
            opacity: 0;
            transform: translateX(-50px) translateY(13vh);
        }

        to {
            opacity: 1;
            transform: translateX(0) translateY(13vh);
        }
    }

    @keyframes fadeInIntroQuote-2 {
        from {
            opacity: 0;
            transform: translateX(-50px) translateY(2vh);
        }

        to {
            opacity: 1;
            transform: translateX(0) translateY(2vh);
        }
    }
}