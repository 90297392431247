/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-navbar {
        width: 90vw;
        height: 50px;
        position: fixed;
        z-index: 1;
        top: 35px;
        background-color: black;
        border: 1px solid rgba(128, 128, 128, 0.433);
        border-radius: 10px;
        transition: transform 0.4s;
    }

    .mobile-navbar.hidden {
        transform: translateY(-30vh);
    }

    .mobile-navbar.visible {
        transform: translateY(0);
    }

    .mobile-navbar-logo {
        height: 100%;
        margin-left: 20px;
    }

    .mobile-navbar-logo img {
        height: 11px;
    }

    .mobile-navigation-options {
        height: 100%;
        right: 20px;
    }

    .mobile-navigation-options img {
        height: 14px;
    }

    .mobile-navigation-sidebar {
        width: 80vw;
        height: 90vh;
        position: fixed;
        right: 0;
        top: 0;
        background-color: black;
        color: white;
        z-index: 2;
        visibility: hidden;
    }
}