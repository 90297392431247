/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-contact-form {
        width: 100vw;
        flex-direction: column;
    }

    .mobile-contact-form-header {
        width: 100%;
        background: url('../../../../public/homepage_assets/images/contact-form-top-bg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
    }

    .mobile-contact-form-header-head {
        margin-top: 16vh;
        margin-left: 6vw;
        font-size: 25px;
    }

    .mobile-contact-form-header-subhead {
        margin-top: 15px;
        margin-left: 6vw;
        font-size: 14px;
        margin-bottom: 35px;
    }

    .mobile-contact-form-body {
        margin-left: 1vw;
        margin-top: 30px;
    }

    .mobile-contact-form-details-entry {
        width: 89vw !important;
        min-width: 89vw !important;
        font-family: 'SK-Modernist';
        font-size: 12px;
        margin-bottom: 20px;
    }

    .mobile-contact-form-details-entry p {
        width: 95%;
        margin: 7px 0px;
    }

    .mobile-contact-form-details-entry-input {
        box-sizing: border-box;
        width: 100%;
        min-width: 100%;
        height: 43px;
        font-size: 13px;
        padding-left: 12px;
        padding-right: 10px;
        border: 1.3px solid black;
        border-radius: 6px;
        background-color: transparent;
        font-family: 'Sk-Modernist';
    }

    .mobile-contact-form-details-entry input:focus {
        border: 1.5px solid #1942F2;
        outline: none;
    }

    .mobile-contact-form-details-entry input::placeholder {
        width: 98%;
    }

    .mobile-contact-form-details-entry-textarea {
        height: 90px;
        padding-top: 10px;
    }

    .mobile-contact-form-details-entry-textarea:focus {
        outline: none;
        border: 1.5px solid #1942F2;
    }

    .mobile-contact-form-details-entry-textarea::placeholder {
        width: 98%;
        font-weight: 400;
        font-style: normal;
    }

    .mobile-contact-phone-input-combo {
        width: 89vw !important;
    }

    .mobile-contact-phone-code-input {
        max-width: 13% !important;
        width: 13% !important;
        height: 40px;
        font-size: 13px;
        padding-left: 12px;
        padding-right: 10px;
        border: 1.3px solid black;
        border-radius: 6px;
        background-color: transparent;
        font-family: 'Sk-Modernist';
        margin-right: 3% !important;
    }

    .mobile-contact-phone-input {
        width: 84% !important;
        max-width: 84% !important;
        height: 40px;
        font-size: 13px;
        padding-left: 12px;
        padding-right: 10px;
        border: 1.3px solid black;
        border-radius: 6px;
        background-color: transparent;
        font-family: 'Sk-Modernist';
    }

    .css-1nmdiq5-menu {
        font-size: 14px !important;
    }

    .ss-9hilw7-control {
        max-width: 90% !important;
    }

    .css-b62m3t-container {
        max-width: 89vw !important;
        min-width: 89vw !important;
    }

    .css-1oj4owq-control {
        width: 100% !important;
        min-height: 43px !important;
        border-radius: 6px !important;
    }

    .css-hlgwow {
        width: 80% !important;
        max-width: 80% !important;
        padding: 0px !important;
        padding-left: 10px !important;
    }

    .css-1wy0on6 {
        width: 15% !important;
        max-width: 15% !important;
        justify-content: end !important;
    }

    .css-1km99j3-placeholder {
        width: 95% !important;
        font-size: 13px !important;
    }

    .css-19bb58m {
        padding: 0px !important;
        margin: 0px !important;
    }

    .css-1dimb5e-singleValue {
        color: black !important;
        font-size: 13px !important;
    }

    .css-1cfo1cf,
    .css-1cfo1cf input {
        padding: 0px !important;
        margin: 0px !important;
        color: black !important;
        font-size: 13px !important;
    }

    .css-1u9des2-indicatorSeparator {
        background-color: rgb(99, 99, 99) !important;
    }

    .css-tj5bde-Svg {
        color: rgb(98, 98, 98) !important;
    }

    .mobile-contact-form-footer {
        width: 87vw;
        margin-top: 30px;
        font-family: 'Poppins';
        font-size: 11px;
        color: rgb(80, 80, 80);
    }

    .mobile-contact-form-submit {
        height: 45px;
        width: 88.5vw;
        font-size: 15px;
        background-color: #1942F2;
        margin-top: 25px;
        color: white;
        border: none;
        margin-bottom: 30px;
        border-radius: 6px;
    }

    .f-i-f-b-select {
        animation: FadeInFromBottom .8s ease-in-out forwards;
    }

    .f-i-f-b-select-stable {
        opacity: 1;
    }

    .f-i-f-b-2 {
        animation: FadeInFromBottom .6s ease-in-out forwards;
    }

    .f-i-f-b-3 {
        animation: FadeInFromBottom .7s ease-in-out forwards;
    }

    .f-i-f-b-4 {
        animation: FadeInFromBottom .8s ease-in-out forwards;
    }
}