/* Desktop - 100 */

@media only screen {

    .home-projects-heading-quote { /* Projects Heading */
        width: 950px !important;
        font-size: 70px;
        letter-spacing: 1px;
    }

    .home-projects-right-arrow {
        border-radius: 50%;
        height: 120px;
        min-width: 120px;
        transition: opacity .3s ease-in-out;
        opacity: 1;
    }

    .home-projects-right-arrow:hover {
        background-color: #b8bc04;
        opacity: 0;
        filter: invert(100%);
        height: 120px;
        width: 120px;
        animation: fadeInOut 0.6s forwards;
    }

    .home-projects-right-arrow img {
        height: 120px;
    }

    .home-projects-right-arrow img:hover {
        height: 133px;
        width: 133px;
    }

    .home-projects-completed { /* Projects Completed */
        margin-top: 70px;
        margin-bottom: 120px;
    }

    .home-projects-completed-count {
        font-size: 63px;
    }

    .home-projects-completed-tag {
        margin-top: 5px;
        color: #1F28CF;
        font-size: 16px;
        min-width: 150px;
    }

    .home-projects-vertical-line {
        width: 2px;
        min-height: 105px;
        height: auto;
        margin: 0 40px;
        background-color: #D3D3D3;
    }

    .home-projects-explanation {
        width: 850px !important;
        font-size: 24px;
        line-height: 35px;
    }

    .home-projects-demo { /* Projects Demo */
        height: 1030px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px;
    }

    .home-project-x {
        height: 500px;
        width: 100%;
        border-radius: 20px;
    }

    .home-project-x-anim-1 {
        opacity: 1;
        animation: moveInFromBottomSmall .5s ease-in-out forwards;
    }

    .home-project-x-anim-2 {
        opacity: 1;
        animation: moveInFromBottomSmall .7s ease-in-out forwards;
    }

    @keyframes moveInFromBottomSmall {
        from {
            transform: translateY(75px);
        }

        to {
            transform: translateY(0);
        }
    }

    .home-project-x-upper-layer {
        width: 110%;
        height: 110%;
        opacity: 0;
        z-index: 1;
    }

    .home-project-x-most-upper-layer {
        width: 86%;
        height: 80%;
        opacity: 0;
        align-items: end;
        z-index: 2;
    }

    .home-project-x:hover .home-project-x-upper-layer {
        animation: FadeIn .2s ease-in-out forwards;
    }

    .home-project-x:hover .home-project-x-most-upper-layer {
        animation: FadeIn .2s ease-in-out forwards;
    }

    .home-project-x .home-project-x-upper-layer {
        animation: fadeOut 0.2s ease-in-out forwards;
    }

    .home-project-x .home-project-x-most-upper-layer {
        animation: fadeOut 0.2s ease-in-out forwards;
    }

    .home-project-x-most-upper-layer-left {
        color: white;
        letter-spacing: .75px;
        width: 83%;
    }

    .home-project-x-most-upper-layer-head {
        font-size: 35px;
    }

    .home-project-x-most-upper-layer-body {
        margin-top: 15px;
        font-size: 16px;
    }

    .home-project-x-most-upper-layer-right img {
        height: 45px;
    }

    .home-project-x img {
        width: 100%;
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {

    .home-projects-heading-quote { /* Projects Heading */
        width: 760px !important;
        font-size: 57px;
    }

    .home-projects-right-arrow {
        height: 98px;
        min-width: 98px;
    }

    .home-projects-right-arrow:hover {
        height: 98px;
        width: 98px;
    }

    .home-projects-right-arrow img {
        height: 98px;
    }

    .home-projects-right-arrow img:hover {
        height: 108.5px;
        width: 108.5px;
    }

    .home-projects-completed { /* Projects Completed */
        margin-top: 58px;
        margin-bottom: 98px;
    }

    .home-projects-completed-count {
        font-size: 53px;
    }

    .home-projects-completed-tag {
        font-size: 13.5px;
        min-width: 125px;
    }

    .home-projects-vertical-line {
        width: 2px;
        min-height: 95px;
        margin: 0 32px;
    }

    .home-projects-explanation {
        width: 680px !important;
        font-size: 19.5px;
        line-height: 29px;
    }

    .home-projects-demo { /* Projects Demo */
        height: 840px !important;
        gap: 20px;
    }

    .home-project-x {
        height: 410px;
        border-radius: 16px;
    }

    .home-project-x-most-upper-layer-head {
        font-size: 29.5px;
    }

    .home-project-x-most-upper-layer-body {
        margin-top: 12px;
        font-size: 12.5px;
    }

    .home-project-x-most-upper-layer-right img {
        height: 36px;
    }
}