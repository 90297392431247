@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https: //fonts.cdnfonts.com/css/roboto');

@font-face {
    font-family: 'NeoSansPro';
    src: url('Fonts/neo-sans-pro/NeoSansProMedium.OTF') format('opentype');
}

@font-face {
    font-family: 'Sk-Modernist';
    src: url('Fonts/Sk-Modernist/Sk-Modernist-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Sk-Modernist-Light';
    src: url('Fonts/Sk-Modernist/Sk-Modernist-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Sk-Modernist-Bold';
    src: url('Fonts/Sk-Modernist/Sk-Modernist-Bold.otf') format('opentype');
}