/* Desktops - 100 */

@media only screen {

    .navbar {
        width: 82%;
        height: 100px;
        z-index: 1;
        top: 35px;
        background-color: black;
        border: 2px solid rgba(128, 128, 128, 0.433);
        border-radius: 18px;
    }

    .navbar-logo {
        height: 100%;
        margin-left: 45px;
    }

    .navbar-logo img {
        height: 25px;
    }

    .navigation-items {
        height: 100%;
        right: 35px;
        color: white;
        font-size: 20px;
        letter-spacing: .5px;
    }

    .navigation-items div {
        transition: transform .3s;
    }

    .navigation-items div:hover {
        transform: scale(1.1);
    }

    .navigation-items div:active {
        transform: scale(1.2);
    }

    .navigation-items a {
        text-decoration: none;
        color: inherit;
        margin: 32px;
        border-bottom: 1px solid transparent;
        transition: border-color .3s ease;
    }

    .navigation-items a:hover {
        border-bottom: 1px solid white;
    }

    .navigation-items-contact {
        height: 38px;
        width: 118px;
        margin-left: 20px;
        font-size: 17px;
        padding-bottom: 2px;
        background-color: white;
        color: black;
        border-radius: 6px;
        transition: transform .3s;
    }

    .navigation-items-contact:hover {
        transform: scale(1.1) !important;
    }

    .navigation-items-contact:active {
        transform: scale(1.1);
    }

    .navigation-options,
    .navigation-sidebar {
        visibility: hidden;
    }
}


/* Laptops - 125 */

@media only screen and (max-width: 1600px) {

    .navbar {
        width: 82%;
        height: 80px;
        top: 30px;
        border-radius: 14px;
    }

    .navbar-logo {
        margin-left: 35px;
    }

    .navbar-logo img {
        height: 21px;
    }

    .navigation-items {
        right: 27px;
        font-size: 16px;
    }

    .navigation-items a {
        margin: 26px;
    }

    .navigation-items-contact {
        height: 32px;
        width: 100px;
        margin-left: 18px;
        font-size: 14px;
        border-radius: 7px;
    }
}


/* Tablet */

@media only screen and (max-width: 1000px) {

    .navigation-options {
        visibility: visible;
    }
}