/* Desktop - 100 */

@media only screen {

    .project-contact {
        padding-top: 420px;
        margin-bottom: 150px;
    }

    .contact-left-gif-animation {
        animation: zoomIn .35s ease-in-out forwards;
    }

    .contact-left-gif {
        height: 140px;
        margin-bottom: 30px;
        transform: translateX(-24%);
    }

    .contact-left-head-animation {
        animation: zoomIn .4s ease-in-out forwards;
    }

    .contact-left-head {
        width: 420px;
        font-size: 60px;
        margin-bottom: 45px;
    }

    .contact-left-desc-animation {
        animation: zoomIn .5s ease-in-out forwards;
    }

    .contact-left-desc {
        width: 550px;
        font-size: 21px;
        word-spacing: 1px;
        line-height: 26px;
        letter-spacing: .3px;
        margin-bottom: 85px;
        color: rgb(113, 113, 113);
    }

    .contact-left-desc span {
        font-size: 20px;
        background: linear-gradient(90deg, #0855F5, #000000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
    }

    .contact-left-box {
        width: 420px;
        border-radius: 8px;
        color: white;
        padding: 55px 70px 55px 65px;
        background-color: black;
    }

    .contact-left-email {
        margin-right: 20px;
    }

    .contact-left-email-icon {
        height: 19px !important;
        width: auto;
        margin-bottom: -3px !important;
    }

    .contact-left-email-head span {
        margin-left: 15px;
        letter-spacing: 1.8px;
        font-size: 21px;
    }

    .contact-left-email-body {
        border-bottom: 1px solid white;
        margin-top: 20px;
        font-size: 19px;
    }

    .contact-left-phone-icon {
        height: 23px !important;
        width: auto;
        margin-bottom: -2px !important;
    }

    .contact-left-phone-head span {
        margin-left: 15px;
        letter-spacing: 1.8px;
        font-size: 22px;
    }

    .contact-left-phone-body {
        margin-top: 20px;
        min-width: 120px;
        font-size: 21px;
    }

    .contact-right {
        color: rgb(41, 41, 41);
        margin-top: 80px;
        font-size: 18px;
        letter-spacing: .5px;
        z-index: 4;
    }

    .contact-right-details {
        display: flex;
        margin-bottom: 45px;
    }

    .contact-right-details-entry {
        width: 285px;
        margin-left: 60px;
    }

    .contact-right-details-entry p {
        margin: 16px 0px;
    }

    .contact-right-details-entry input,
    select {
        width: 100%;
        height: 40px;
        font-size: 15px;
        padding-left: 10px;
        border: none;
        pointer-events: auto;
        cursor: text;
        border-bottom: 2px solid #A5A5A5;
        background-color: transparent;
    }

    .contact-right-details-entry input:hover,
    select:hover {
        cursor: default;
    }

    .contact-right-details-entry input:focus,
    select:focus {
        border: none;
        outline: none;
        border-bottom: 2px solid #A5A5A5;
    }

    .contact-right-details-entry select {
        width: 104% !important;
        margin-top: 5px;
        font-size: 15px;
    }

    .contact-right-message {
        width: 91% !important;
        margin-left: 60px;
    }

    .contact-right-submit {
        width: 93%;
        letter-spacing: .5px;
        border-radius: 5px;
        font-size: 21px;
        margin-top: 85px;
        border: 2px solid black;
        height: 56px;
        padding-bottom: 1px;
        margin-left: 60px;
        background-color: white;
    }

    .contact-right-submit:hover {
        font-weight: normal;
        color: white;
        background-color: #1F28CF;
        border: 2px solid transparent;
        transition: opacity .3s ease-in;
        animation: fadeInOut .6s forwards;
    }

    .contact-right-submit div {
        width: 100%;
        height: 100%;
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {


    .project-contact {
        padding-top: 340px;
        margin-bottom: 120px;
    }

    .contact-left-gif {
        height: 112px;
        margin-bottom: 24px;
    }

    .contact-left-head {
        width: 340px;
        font-size: 50px;
        margin-bottom: 36px;
    }

    .contact-left-desc {
        width: 440px;
        font-size: 16px;
        word-spacing: .8px;
        line-height: 20px;
        letter-spacing: .2px;
        margin-bottom: 68px;
    }

    .contact-left-desc span {
        font-size: 16px;
    }

    .contact-left-box {
        width: 340px;
        border-radius: 6px;
        padding: 44px 56px 44px 52px;
    }

    .contact-left-email {
        margin-right: 16px;
    }

    .contact-left-email-icon {
        height: 16px !important;
    }

    .contact-left-email-head span {
        margin-left: 12px;
        letter-spacing: 1.5px;
        font-size: 17px;
    }

    .contact-left-email-body {
        margin-top: 16px;
        font-size: 15px;
    }

    .contact-left-phone-icon {
        height: 18.5px !important;
    }

    .contact-left-phone-head span {
        margin-left: 11px;
        letter-spacing: 1.2px;
        font-size: 17.5px;
    }

    .contact-left-phone-body {
        margin-top: 16px;
        min-width: 96px;
        font-size: 17px;
    }

    .contact-right {
        margin-top: 68px;
        font-size: 14.5px;
        letter-spacing: .4px;
    }

    .contact-right-details {
        margin-bottom: 36px;
    }

    .contact-right-details-entry {
        width: 228px;
        margin-left: 48px;
    }

    .contact-right-details-entry p {
        margin: 12px 0px;
    }

    .contact-right-details-entry input,
    select {
        height: 32px;
        font-size: 12px;
        padding-left: 8px;
    }

    .contact-right-details-entry select {
        margin-top: 3.8px;
        font-size: 12px;
    }

    .contact-right-message {
        margin-left: 48px;
    }

    .contact-right-submit {
        letter-spacing: .4px;
        font-size: 17px;
        margin-top: 68px;
        height: 45px;
        margin-left: 48px;
    }
}