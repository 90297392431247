/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-bottom-about {
        width: 95vw;
        background-color: black;
        color: white;
        transform: translateY(-36vw);
        z-index: 0;
        padding-left: 5vw;
    }

    .mobile-bottom-about-services {
        margin-top: 110px;
    }

    .mobile-bottom-about-services-head {
        font-size: 16px;
        letter-spacing: .2px;
    }

    .mobile-bottom-about-services-list {
        width: 90vw;
        margin-top: 15px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 10px;
    }

    .mobile-bottom-about-services-item {
        font-size: 13px;
        letter-spacing: .3px;
    }

    .mobile-bottom-about-queries {
        margin-top: 30px;
    }

    .mobile-bottom-about-queries-head {
        font-size: 16px;
        letter-spacing: .2px;
    }

    .mobile-bottom-about-queries-email {
        margin-top: 15px;
        font-size: 13px;
        letter-spacing: .3px;
    }

    .mobile-bottom-about-follow {
        width: 90vw;
        margin-top: 40px;
        flex-direction: column;
    }

    .mobile-bottom-about-follow-head {
        font-size: 14px;
    }

    .mobile-bottom-about-social-media {
        width: 55vw;
        margin-top: 25px;
    }

    .mobile-bottom-about-social-media-item {
        height: 30px;
        width: 30px;
        border: .7px solid rgb(124, 124, 124);
        border-radius: 50%;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform .5s;
    }

    .mobile-bottom-about-social-media-item:active {
        transform: scale(1.5);
    }

    .mobile-bottom-about-tevah-logo {
        width: 90vw;
        margin-top: 45px;
        flex-direction: column;
    }

    .mobile-bottom-about-tevah-logo img {
        height: 20px;
    }

    .mobile-bottom-about-arc-of-design {
        margin-top: 10px;
        font-size: 14px;
        letter-spacing: .2px;
    }

    .mobile-bottom-about-privacy-cookies {
        width: 90vw;
        margin-top: 30px;
        font-size: 14px;
    }

    .mobile-bottom-about-privacy {
        border-bottom: .7px solid white;
    }

    .mobile-bottom-about-cookies {
        border-bottom: .7px solid white;
    }

    .mobile-bottom-about-pc-vertical-line {
        height: 15px;
        width: 1px;
        margin: 0px 10px;
        margin-top: 4px;
        background-color: #3A3A3A;
    }

    .mobile-bottom-about-rights {
        width: 90vw;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 14px;
    }

    .z-i-m-1 {
        animation: zoomIn .3s ease-in-out forwards;
    }

    .z-i-m-2 {
        animation: zoomIn .5s ease-in-out forwards;
    }

    .z-i-m-3 {
        animation: zoomIn .7s ease-in-out forwards;
    }

    .z-i-m-4 {
        animation: zoomIn .9s ease-in-out forwards;
    }
}