/* Desktop - 100 */

@media only screen {

    .what-we-do {
        height: auto;
        width: 150vw;
        flex-direction: column;
        background-color: black;
        transform: translateX(-22.6%);
        color: white;
    }

    .what-we-do-heading-min {
        min-height: 170px;
        width: 300px;
        text-align: center;
    }

    .what-we-do-heading {
        line-height: 0;
    }

    .what-we-do-heading img {
        margin-top: 80px;
        margin-bottom: 70px;
        height: 18px;
    }

    .what-we-do-quotes-min {
        height: 200px;
        width: 800px;
        text-align: center;
    }

    .what-we-do-quote {
        height: 200px;
        width: 100%;
        font-size: 80px;
        letter-spacing: 2px;
    }

    .our-services {
        width: 180px;
        height: 42px;
        letter-spacing: .2px;
        padding-bottom: 3px;
        margin-top: 60px;
        margin-bottom: 120px;
        font-size: 19px;
        border-radius: 4px;
        border: 1.5px solid white;
    }

    .our-services:hover {
        background-color: #1F28CF;
        border: 1.5px solid transparent;
        transition: opacity .3s ease-in;
        animation: fadeInOutOurServices .6s forwards;
    }

    @keyframes fadeInOutOurServices {
        0% {
            opacity: 0;
        }

        10% {
            opacity: .3;
        }

        100% {
            opacity: 1;
        }
    }

    .what-we-do-services {
        display: grid;
        width: 1705px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content;
        z-index: 4;
    }

    .what-we-do-box { /* Whatwedo Box */
        position: relative;
        height: 480px;
    }

    .what-we-do-box-background {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: transparent;
        background-image: url('../../../../public/homepage_assets/images/shadows/what-we-do-hover.png');
        background-size: cover;
        background-position: center;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
    }

    .what-we-do-box:hover .what-we-do-box-background {
        opacity: 1;
    }

    .what-we-do-text-animation-left {
        animation: fadeInLeftToRight .6s ease-in-out forwards;
        height: 80%;
    }

    .what-we-do-text-animation-center {
        animation: FadeIn .6s ease-in-out forwards;
        height: 80%;
    }

    .what-we-do-text-animation-right {
        animation: fadeInRightToLeft .6s ease-in-out forwards;
        height: 80%;
    }

    .what-we-do-box-foreground {
        height: 100%;
        margin: 50px;
        flex-direction: column;
    }

    .what-we-do-text-heading {
        width: 70%;
        z-index: 1;
        margin-bottom: 45px;
        letter-spacing: 1.2px;
        font-size: 33px;
    }

    .what-we-do-text-desc {
        width: 95%;
        margin-bottom: 40px;
        z-index: 1;
        font-size: 19px;
        line-height: 25px;
        letter-spacing: .5px;
    }

    .what-we-do-learn-more {
        width: 37%;
        z-index: 1;
        font-size: 25px;
    }

    .what-we-do-learn-more img {
        height: 25px;
        margin-left: 13px;
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {

    .what-we-do-heading-min {
        min-height: 136px;
    }

    .what-we-do-heading img {
        margin-top: 65px;
        margin-bottom: 56px;
        height: 15px;
    }

    .what-we-do-quotes-min {
        height: 160px;
        width: 650px;
    }

    .what-we-do-quote {
        height: 160px;
        font-size: 65px;
        letter-spacing: 1.5px;
    }

    .our-services {
        width: 144px;
        height: 34px;
        margin-top: 48px;
        margin-bottom: 96px;
        font-size: 15px;
    }

    .what-we-do-services {
        width: 1365px;
    }

    .what-we-do-box { /* Whatwedo Box */
        height: 385px;
    }

    .what-we-do-box-foreground {
        margin: 40px;
    }

    .what-we-do-text-heading {
        margin-bottom: 36px;
        font-size: 27px;
    }

    .what-we-do-text-desc {
        margin-bottom: 32px;
        font-size: 15.5px;
        line-height: 20px;
    }

    .what-we-do-learn-more {
        font-size: 20px;
    }

    .what-we-do-learn-more img {
        height: 20px;
        margin-left: 10px;
    }
}