
.webpage-align {
    width: 82%;
}

.services-intro {
    display: flex;
    margin-top: 150px;
}

.services-intro-title {
    width: 60%;
    margin-top: 200px;
    font-family: "Sk-Modernist", sans-serif;
    font-size: 70px;
    font-weight: 400;
}

.services-intro-subtitle {
    width: 500px;
    height: 87px;
    margin-top: 479.2px;
    font-family: "Sk-Modernist", sans-serif;
    font-size: 19px;
    font-weight: 400;
    color: #565656;
}

.services-intro-outlined-button {
    top: 612.8px;
    width: 220px;
    height: 50px;
    gap: 0px;
    border-radius: 6px;
    border: 3px solid #000;
    background-color: transparent;
    font-family: "Sk-Modernist", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-intro-top-bubble {
    position: absolute;
    z-index: -1;
    right: 150px;
}

.services-intro-center-image img {
    height: 420px;
}

.services-intro-bottom-bubble {}


/* ////////// MAGIC UI ////////// */


.text-reveal {
    position: absolute;
    top: 1500px;
}