/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-our-vision {
        padding-left: 5vw;
        background-color: white;
        transform: translateY(-70vw);
    }

    .mobile-our-vision-mini-head {
        padding-top: 60px;
        color: #1942F2;
        font-size: 16px;
        letter-spacing: .5px;
    }

    .mobile-our-vision-head {
        width: 75vw;
        margin-top: 20px;
        font-size: 26px;
        letter-spacing: .5px;
    }

    .mobile-our-vision-body {
        width: 90vw;
        margin-top: 20px;
        font-size: 14px;
    }

    .mobile-our-vision-grid {
        width: 90vw;
        margin-top: 30px;
        display: grid;
        gap: 5vw 2vw;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .mobile-our-vision-badge {
        display: flex;
        flex-direction: column;
    }

    .mobile-our-vision-badge-value {
        margin-bottom: 10px;
        font-family: 'Sk-Modernist-Bold';
        font-size: 33px;
    }

    .mobile-our-vision-badge-name {
        letter-spacing: .2px;
        font-size: 13px;
        font-family: 'Sk-Modernist';
    }
}