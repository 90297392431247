/* Mobile */

@media only screen and (max-width: 500px) {

    .mobile-projects {
        margin-top: 45px;
        margin-bottom: 25px;
        flex-direction: column;
    }

    .mobile-projects-container {
        min-height: 345px;
        width: 90%;
    }

    .mobile-projects-image-container {
        height: 220px;
        border-radius: 12px;
    }

    .mobile-projects-image-container img {
        width: 100%;
    }

    .mobile-projects-text-container {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: .2px;
    }

    .mobile-projects-view-all-works {
        width: 90%;
        height: 45px;
        border: 1.6px solid black;
        border-radius: 5px;
        font-size: 15px;
    }
}