/* Desktop - 100 */

@media screen {

    .homepage {
        width: 100vw;
        z-index: 0;
    }

    .homepage-top-light-left {
        left: 50%;
        transform: translateX(-70%);
        top: -130px;
    }

    .homepage-top-light-right {
        left: 50%;
        transform: translateX(-35%);
        top: -130px;
    }

    .projects-to-rest {
        width: 82%;
        top: 85.7vh;
        animation: moveInFromBottom .7s ease-in-out forwards;
    }

    .projects-to-rest-moved {
        width: 82%;
        top: 132.8vh;
        animation: moveInFromTop .55s ease-in-out forwards;
    }

    .home-moving-box {
        width: 100%;
        height: 600px !important;
        position: relative;
        margin-bottom: 100px;
        border-radius: 20px;
        background-color: #F2F4F7;
    }

    .autoplay-video {
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }

    .achieve-top-lights {
        width: 150vw;
        padding-top: 50px;
        transform: translateX(-22.6%) translateY(-5px);
        background-color: black;
        overflow: hidden;
    }

    .achieve-light1 {
        transform: rotate(180deg) translateX(-55%) translateY(32%) scaleX(-1);
        -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
    }

    .achieve-light2 {
        transform: rotate(180deg) translateX(-54%) translateY(32%) scaleX(-1);
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-composite: intersect;
    }

    .achieve-bottom-lights {
        width: 100vw;
        transform: translateX(-9vw);
        left: 50%;
    }

    .achieve-bottom-light1 {
        transform: translateX(-51%) translateY(27%);
        -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
    }

    .achieve-bottom-light2 {
        transform: translateX(-120%) translateY(34%);
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-composite: intersect;
    }
}

/* Laptop - 125 */

@media only screen and (max-width: 1600px) {

    .projects-to-rest-moved {
        top: 133.8vh;
    }

    .home-moving-box {
        height: 500px !important;
    }

    .achieve-top-lights {
        transform: translateX(-33%) !important;
        padding-top: 0px;
        height: 400px;
    }

    .achieve-light1 {
        transform: rotate(180deg) translateX(-55%) translateY(25%) scaleX(-1);
    }

    .achieve-light2 {
        transform: rotate(180deg) translateX(-54%) translateY(25%) scaleX(-1);
    }
}